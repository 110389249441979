'use strict'
function onLoadPage() {
  // detectFIDOUsers();
  showError()
}

function showError() {
  if (
    document.getElementById('errId').getAttribute('data-error') !== '' &&
    document.getElementById('errId').getAttribute('data-error') !== '@ERROR_MESSAGE@'
  ) {
    document.getElementById('error-box').classList.remove('d-none')
  }
}

window.addEventListener('load', onLoadPage)
